<template>
  <div class="page">
    <div class="particular">
      <div class="left">当前房号</div>
      <div class="right">{{ form.chosenSpacePath }}</div>
    </div>
    <div class="particular">
      <div class="left">真实姓名</div>
      <div class="right">
        <input
          v-model="form.realName"
          type="text"
          placeholder="请填写真实姓名"
          :disabled="form.realName != ''"
        />
      </div>
    </div>
    <div class="particular">
      <div class="left">是否产权人</div>
      <div class="right">{{ format(form.identity) }}</div>
    </div>
    <div class="particular">
      <div class="left">手机号码</div>
      <div class="right">{{ form.mobileNum }}</div>
    </div>
    <div class="section-item signcCanvas">
      <div class="section-item--label">
        <span>电子签名</span>
        <div class="reset" @click="handleReset">
          <span>重置</span>
        </div>
      </div>
      <div class="section-item--control">
        <div class="sign">
          <img v-if="!isShowsignCanvas" :src="form.esignature" alt="" />
          <signCanvas
            v-else
            ref="esign"
            :width="614"
            :height="242"
            :isCrop="isCrop"
            :lineWidth="lineWidth"
            :lineColor="lineColor"
            :bgColor.sync="bgColor"
          />
        </div>
      </div>
    </div>
    <!-- <div class="particular"></div> -->
    <p class="message" v-if="form.identity == 0 || form.identity == 1">
      上传房产证（请上传包含产权人姓名及房号信息的部分即可）
    </p>
    <v-upload
      v-if="form.identity == 0 || form.identity == 1"
      class="oItem-b_upload"
      :imgUrl.sync="form.houseownerCertPhoto"
      :activityPicture.sync="form.houseownerCertPhoto"
      :maxCount="1"
      ref="load"
    ></v-upload>
    <!-- <div class="particular"></div> -->
    <p class="message" v-if="form.identity == 1">
      上传结婚证证（请上传包含自己和配偶姓名的部分即可）
    </p>
    <v-upload
      v-if="form.identity == 1"
      class="oItem-b_upload"
      :imgUrl.sync="form.marriageCertPhoto"
      :activityPicture.sync="form.marriageCertPhoto"
      :maxCount="1"
      ref="load"
    ></v-upload>
    <div class="btn" @click="submitBefore">提交</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { base64toFile } from "@/utils/utils.js";
import {
  userInfoUrl,
  applyHouseOwnerAuditUrl,
  getProvidedRealNameUrl,
  uploadURL,
} from "./api.js";
import signCanvas from "@/components/bussiness/signCanvas";

export default {
  name: "identityAuthentication",
  components: {
    signCanvas,
  },
  data() {
    return {
      form: {
        houseownerCertPhoto: "",
        marriageCertPhoto: "",
        identity: null,
        mobileNum: "",
        realName: "",
        chosenSpacePath: "",
        houseId: "",
        esignature: "",
      },
      userInfo: {},
      /** 电子签名 */
      isShowsignCanvas: true,
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    this.getUserInfo();
    await this.getRealName();
    console.log(this.$route.query.type);
  },
  methods: {
    async getRealName() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getProvidedRealNameUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.form.realName = res.data;
      }
    },
    // 清空签名
    handleReset() {
      this.isShowsignCanvas = true;
      this.form.esignature = "";
      this.$nextTick(() => {
        this.$refs.esign.reset();
      });
    },
    submitBefore() {
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.houseownerCertPhoto) {
        this.$toast("请上传房产证图片");
        return;
      }
      if (this.form.realName == "") {
        this.$toast("请输入真实姓名");
        return;
      }
      // if (!this.form.esignature) {
      //   this.$toast("请绘制电子签名");
      //   return;
      // }
      if (this.form.identity == 1 && !this.form.marriageCertPhoto) {
        this.$toast("请上传结婚证图片");
        return;
      }

      if (!this.isShowsignCanvas) {
        // 已存在签名图片,直接提交
        this.submit();
      } else {
        // 未存在签名图片
        this.handleGenerate();
      }
    },
    /* signCanvas start */
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          // alert(err); // 画布没有签字时会执行这里 'Not Signned'
          // if (err == "Not Signned") {
          //   this.$toast("未签名");
          // } else {
          this.$toast(err);
          // }
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(uploadURL, formData);
      if (res.code == 200) {
        this.form.esignature = res.data.link;
        this.submit();
      }
    },
    submit() {
      let params = {
        houseownerCertPhoto: this.form.houseownerCertPhoto,
        marriageCertPhoto: this.form.marriageCertPhoto,
        identity: this.form.identity,
        mobileNum: this.form.mobileNum,
        realName: this.form.realName,
        chosenSpacePath: this.form.chosenSpacePath,
        houseId: this.form.houseId,
        eSignature: this.form.esignature,
        userId: this.userId,
      };
      this.$axios.post(`${applyHouseOwnerAuditUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "提交成功！", duration: 700 });
          setTimeout(() => {
            if (Number(this.$route.query.type) == 1) {
              this.$router.push({
                name: "voteList",
              });
            } else if (Number(this.$route.query.type) == 2) {
              if (wx.miniProgram) {
                wx.miniProgram.navigateTo({
                  url: `/subPages/my/myRoom`,
                });
              }
            }
          }, 700);
        }
      });
    },
    // 获取用户信息
    async getUserInfo() {
      let params = {
        userId: this.userId,
      };
      const res = await this.$axios.get(userInfoUrl, {
        params,
      });
      if (res.code === 200) {
        this.userInfo = res.data;
        this.form.mobileNum = res.data.mobile;
        this.form.chosenSpacePath = res.data.chosenSpacePath;
        this.form.houseId = res.data.chosenSpaceId;
        this.form.identity = res.data.identity;
        /** 电子签名 */
        this.form.esignature = res.data.esignature;
        // 是否展示签名组件 - 存在图片则不显示组件
        this.isShowsignCanvas = !this.form.esignature;
        console.log(
          this.form.esignature,
          this.isShowsignCanvas,
          "this.isShowsignc"
        );
      }
    },
    format(num) {
      if (num == 0) {
        return "产权人本人";
      } else if (num == 1) {
        return "产权人配偶";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  padding: 0 19px 0 25px;
  box-sizing: border-box;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    margin: 48px auto 64px;
    // position: fixed;
    // bottom: 126px;
    // left: 50%;
    // transform: translateX(-50%);
  }
  .message {
    margin: 20px 0;
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
  }
  .particular {
    width: 100%;
    height: 100px;
    // background-color: antiquewhite;
    display: flex;
    line-height: 100px;
    .left {
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      text-align-last: left;
    }
    .right {
      flex: 1;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      text-align: right;
      input {
        width: 100%;
        height: 100%;
        text-align: right;
        color: rgba(0, 0, 0, 0.5);
      }
      input:disabled {
        background-color: transparent !important;
      }
      input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  .section-item {
    display: flex;
    .section-item--label {
      flex: 1;
      line-height: 88px;
      color: #333333;
      font-size: 30px;
      text-align: left;
      font-weight: 400;
      white-space: nowrap;
    }
    .section-item--control {
      max-width: 400px;
    }
    &.signcCanvas {
      display: block;
      .section-item--label {
        display: flex;
        align-items: center;
        span {
          flex: 1;
        }
        .reset {
          font-size: 28px;
          color: #0578fa;
          letter-spacing: 4px;
        }
      }
      .section-item--control {
        box-sizing: border-box;
        max-width: none;
        .sign {
          margin-bottom: 12px;
          border-radius: 16px;
          overflow: hidden;
          img {
            width: 100%;
            height: 242px;
          }
        }
      }
    }
  }
}
</style>
